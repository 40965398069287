@import "styles/variables.scss";

.container {
  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 80px;
    width: 366px;
    padding-left: 48px;
    padding-right: 48px;
    flex-shrink: 0;
  }

  @media (min-width: $npa-desktop-large-min-width) {
    margin-top: 120px;
    width: 392px;
  }
}

.header {
  display: none;
  font-family: $primary-font, $primary-font-fallback;
  font-size: 18px;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 20px;

  @media (min-width: $npa-desktop-small-min-width) {
    display: block;
  }
}

.header_button {
  border: none;
  border-radius: 0px 0px 24px 24px;
  text-align: left;
  background-color: $orange-100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding-top: 48px;
  width: calc(100% - 40px);
  cursor: pointer;
  min-height: 72px;
  margin-top: -18px;
  margin-left: 20px;
  margin-right: 20px;

  &:active,
  &:focus {
    outline: none;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    display: none;
  }

  // .header_button_icon_container
  &_icon_container {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
    color: $warm-orange;
    border-radius: 60px;
    padding: 4px 8px;
    background-color: $orange-200;
    display: flex;
    justify-content: center;
    align-items: center;

    // .plans_visible .header_button_icon_container
    .plans_visible & {
      transform: rotate(180deg);
    }
  }

  // .header_button_text_container
  &_text_container {
    display: flex;
    flex-direction: column;
    padding-right: 16px;
  }

  // .header_button_text_line1
  &_text_line1 {
    font-family: $secondary-font, $primary-font-fallback;
    font-size: 14px;
    line-height: 20px;
  }

  // .header_button_text_line1
  &_text_line2 {
    color: $genesis-orange;
    font-family: $primary-font, $primary-font-fallback;
    font-size: 14px;
    line-height: 20px;
  }
}

// .plans_visible .header_button_icon_container {
//   transform: rotate(0deg);
// }

.body {
  display: none;
  margin: 0 16px;
  max-width: 100%;

  @media (min-width: $npa-desktop-small-min-width) {
    display: block;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 180px;
  }

  .plans_visible & {
    display: block;
    border-bottom: 2px solid $genesis-orange;

    @media (min-width: $npa-desktop-small-min-width) {
      border-bottom: none;
    }
  }
}

.feature_list_container {
  margin-bottom: 40px;
}

.plan_card_container {
  padding-bottom: 60px;
  margin-bottom: 40px;
}
