@import "styles/variables.scss";

.button {
  border: none;
  appearance: none;
  background: white;
  display: flex;
  flex: 1;
  padding: 26px 20px;
  border-radius: 6px;
  align-items: center;
  transition: 0.1s ease-out;
  box-shadow: 0px 4px 14px rgba(70, 73, 88, 0);
  cursor: pointer;
  transform: translateZ(0);

  &.small {
    border-radius: 8px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 8px;
    }
  }

  &.medium {
    border-radius: 8px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 12px;
    }
  }

  &.large {
    border-radius: 16px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 20px;
    }
  }

  &:hover {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);
  }

  &:focus,
  &:active {
    box-shadow: 0px 4px 34px rgba(70, 73, 88, 0.1);
    outline: none;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    padding: 48px 40px;
  }

  // .button.active
  &.active {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);

    &:focus,
    &:active {
      box-shadow: 0px 4px 34px rgba(70, 73, 88, 0.1);
    }
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #f58025;
  margin-right: 12px;
  flex-shrink: 0;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-right: 24px;
  }
}

.icon {
  display: flex;
  width: 16px;
  height: 16px;
  background-color: #f58025;
  border-radius: 50%;
  transform: scale(0) translateZ(0);
  transition: 0.15s transform ease-in;

  .active & {
    transform: scale(1) translateZ(0);
  }
}
