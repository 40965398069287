@import "styles/variables.scss";

.container {
  width: 632px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media (min-width: $npa-desktop-large-min-width) {
    width: 840px;
  }
}

.body {
  margin-bottom: 40px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 60px;
  }
}

.feedback_wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: hidden;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.feedback_container {
  overflow: hidden;
}

.row {
  display: flex;
  flex-direction: column;

  @media (min-width: $npa-desktop-small-min-width) {
    flex-direction: row;
  }
}

.column {
  flex: 1;
  @media (min-width: $npa-desktop-small-min-width) {
    margin-left: 24px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.title_field_container {
  @media (min-width: $npa-desktop-small-min-width) {
    max-width: 184px;
  }
}

.text_container {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: $primary-font, $primary-font-fallback;
  font-size: 15px;
  line-height: 24px;

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 18px;
    line-height: 28px;
  }
}

.text_note_container {
  margin-top: 0;
  margin-bottom: 40px;
  font-family: $primary-font, $primary-font-fallback;
  font-size: 15px;
  line-height: 24px;

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 60px;
  }
}

.radio_container {
  margin-bottom: 40px;
  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 60px;
  }
}

.radio_label {
  margin-top: 0;
  margin-bottom: 20px;
  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 0;
  }
}

.radio_column {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 0;
  }
}

.small_print {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 13px;
  line-height: 20px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 60px;
    margin-bottom: 60px;
    font-size: 14px;
    line-height: 20px;
  }

  // .small_print_link
  &_link {
    color: $genesis-orange;
    text-decoration: none;
    border-bottom: 1px solid $genesis-orange;
    padding-bottom: 2px;
    transition: 0.2s color ease-in-out;

    // .suggestions_footer_link:hover
    &:hover {
      color: $genesis-orange-700;
      border-bottom-color: transparent;
    }
  }
}
