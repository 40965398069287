@import "styles/variables.scss";

.header-wrapper {
  position: relative;
  top: 12px;
  left: 0;
  right: 0;
  // height: 60px;
  background-color: white;
  z-index: 2;
  border-bottom: 1px solid $gray-900c;
  @media (min-width: $npa-desktop-small-min-width) {
    position: fixed;
    top: 20px;
  }
}

.header {
  display: flex;
  height: 76px;
  @media (min-width: $npa-desktop-small-min-width) {
    height: 100px;
  }

  // .npa-area-header__logo-wrapper
  &__logo-wrapper {
    width: 60px;
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    position: relative;
    z-index: 1;

    &::before {
      content: " ";
      height: 100%;
      width: 0;
      background: linear-gradient(90deg, white, transparent);
      position: absolute;
      top: 0;
      left: 60px;
      pointer-events: none;

      .scrollable & {
        width: 144px;
      }
    }

    @media (min-width: $npa-desktop-small-min-width) {
      width: 90px;

      &::before {
        left: 90px;
      }
    }
  }

  // .npa-area-header__logo
  &__logo {
    display: block;
    width: 100%;
    margin-bottom: -6px;
    z-index: 1;

    @media (min-width: $npa-desktop-small-min-width) {
      margin-bottom: -16px;
    }
  }

  &__logo img {
    display: block;
  }

  // .npa-area-header__logo_shadow
  &__logo_shadow {
    position: absolute;
    left: -1px;
    right: -1px;
    bottom: -7px;
    height: 7px;
    overflow: hidden;

    @media (min-width: $npa-desktop-small-min-width) {
      height: 17px;
      bottom: -17px;
    }

    &::after {
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      height: 60px;
      background-color: $gray-900c;
      position: absolute;
      border-radius: 50%;

      @media (min-width: $npa-desktop-small-min-width) {
        height: 90px;
      }
    }
  }

  // .npa-area-header__back
  &__back {
    display: flex;
    align-items: flex-end;
  }
  // .npa-area-header__back-history
  &__back-history {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-right: -15px;
    width: 60px;
    height: 60px;
    border-left: 1px solid #eeeeee;
    @media (min-width: $npa-desktop-small-min-width) {
      display: none;
    }
  }
  &__back-history-icon {
    width: 14px;
    height: 28px;
    background: {
      repeat: no-repeat;
      // image: url("/genesis/Resources/app/images/icon-large-chevron.svg");
      size: 14px 28px;
      position: center center;
    }
  }

  // .npa-area-header__back-link
  &__back-link {
    display: flex;
    align-items: center;
    color: $primary-monochrome;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.3px;
    padding-bottom: 13px;

    &:visited,
    &:hover,
    &:active,
    &:focus {
      color: $primary-monochrome;
      text-decoration: none;
    }
  }

  // .npa-area-header__back-link-icon
  &__back-link-icon {
    font-size: 15px;
    margin-right: 7px;
    display: flex;
    align-items: center;
  }

  // .npa-area-header__back-link-text
  &__back-link-text {
    text-decoration: underline;
  }
}

.infoBar {
  display: flex;
  flex: 1;
  padding-left: 16px;
  position: relative;
  // justify-content: flex-end;
  max-width: calc(100% - 44px);
  direction: rtl;
  overflow-x: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

.list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  padding: 10px;
}

.back_container {
  height: 100%;
  display: flex;
  align-items: center;
}

.back {
  text-decoration: none;
  color: $primary-color;
  font-family: $primary-font, $primary-font-fallback;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;

  display: flex;
  align-items: center;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    text-decoration: none;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 18px;
    line-height: 20px;
  }

  // .back_icon
  &_icon {
    transition: 0.2s ease-out;
    transform: translateX(0) rotate(90deg);
    @media (min-width: $npa-desktop-small-min-width) {
      margin-right: 8px;
    }
  }

  // .back:hover .back_icon
  &:hover .back_icon {
    transform: translateX(-4px) rotate(90deg);
  }
}
