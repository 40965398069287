@import "styles/variables.scss";

.container {
  border: 2px solid white;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  transition: 0.2s border-color ease-in-out;
  display: flex;
  flex-direction: column;

  //.container:hover
  &:hover {
    border-color: #c8c8cd;
  }

  // .container_open
  // .container:focus-within
  // .container_open:hover
  // .container:focus-within:hover
  &_open,
  &:focus-within,
  &_open:hover,
  &:focus-within:hover {
    border-color: $primary-color;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    border-radius: 20px;
  }
}

.inputContainer {
  display: flex;
  align-items: stretch;
}

.input {
  display: flex;
  flex: 1;
  width: 100%;
  appearance: none;
  border: none;
  padding: 32px 0 32px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;

  &:focus,
  &:active {
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    color: #464958;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 24px;
    line-height: 24px;
    padding: 48px 0 48px 30px;
  }
}

.input[disabled] {
  background-color: white;
}

%inputIcon {
  color: $primary-color;
  position: absolute;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.inputIconSearch {
  @extend %inputIcon;
  opacity: 1;
}

.inputIconLoading {
  @extend %inputIcon;
  animation: spin 0.5s infinite linear;
  opacity: 0;
}

.inputIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 20px;
  color: $primary-color;
  position: relative;
  width: 64px;

  @media (min-width: $npa-desktop-small-min-width) {
    padding: 40px 32px;
    width: 100px;
  }

  // .inputIconContainer.busy
  &.busy {
    // .inputIconContainer.busy .inputIconSearch
    .inputIconSearch {
      opacity: 0;
    }
    // .inputIconContainer.busy .inputIconLoading
    .inputIconLoading {
      opacity: 1;
    }
  }
}

.suggestions {
  // .suggestions_wrapper
  &_wrapper {
    background-color: white;
    max-height: 394px;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    margin-right: 4px;
    position: relative;
    border-top: 1px solid transparent;

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      width: 6px;
      background-color: #a3a4ac;
    }

    // .suggestions_container_scrolled
    &_scrolled {
      border-top-color: #ededef;
    }
  }

  // .suggestions_header
  &_header {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    margin: 0;
    margin-bottom: 4px;
    padding: 16px 20px;
    color: $primary-color;
    border-bottom: 1px solid #ededef;

    @media (min-width: $npa-desktop-small-min-width) {
      padding: 16px 30px;
    }
  }

  // .suggestions_list
  &_list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-right: -4px;
    margin-bottom: 4px;
    position: relative;
  }

  // .suggestions_footer
  &_footer {
    background-color: #f4f4f4;
    padding: 12px 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;

    @media (min-width: $npa-desktop-small-min-width) {
      padding: 24px 30px;
      font-size: 15px;
      line-height: 24px;
    }
  }

  // .suggestions_footer_text
  &_footer_text {
    margin: 0;
  }

  // .suggestions_footer_link
  &_footer_link {
    color: $genesis-orange;
    text-decoration: none;
    appearance: none;
    border: none;
    padding: 0;
    text-align: left;
    background: transparent;
    cursor: pointer;
    border-bottom: 1px solid $genesis-orange;
    padding-bottom: 2px;
    transition: 0.2s color ease-in-out;

    // .suggestions_footer_link:hover
    &:hover {
      color: $genesis-orange-700;
      border-bottom-color: transparent;
    }
  }
}

.suggestion {
  // .suggestion_item
  &_item {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    border-top: 1px solid #ededef;
    cursor: pointer;
  }

  // .suggestion_item_highlighted
  // .suggestion_item_highlighted + .suggestion_item
  &_item_highlighted,
  &_item_highlighted + &_item {
    border-top-color: transparent;
  }

  // .suggestion_item_inner
  &_item_inner {
    margin-left: 4px;
    margin-right: 8px;
    padding: 16px;
    border-radius: 6px;

    // .suggestion_item_highlighted
    // .suggestion_item:hover
    .suggestion_item_highlighted &,
    .suggestion_item:hover & {
      background-color: #f26d1b; // $primary-color;
      color: white;
      // border-top-color: $primary-color;
    }

    @media (min-width: $npa-desktop-small-min-width) {
      padding: 16px 26px;
    }
  }

  // .suggestion_item_first
  &_item_first {
    border-top-color: transparent;
  }
}

.no_results_container {
  overflow: hidden;
  padding: 16px 20px;
  border-top: 1px solid #ededef;
  display: flex;
  flex-wrap: wrap;
  background-color: $senary-monochrome;
}

.no_results_title {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  margin-right: 4px;
}

.no_results_text {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
}

.no_results_link {
  color: $genesis-orange;
  text-decoration: none;
  transition: 0.2s color ease-in-out;
  appearance: none;
  border: none;
  padding: 0;
  text-align: left;
  background: transparent;
  cursor: pointer;
  border-bottom: 1px solid $genesis-orange;
  padding-bottom: 2px;

  // .no_results_link:hover
  &:hover {
    border-bottom-color: transparent;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
