@import "styles/variables.scss";

.button {
  border: none;
  appearance: none;
  background: white;
  display: flex;
  flex: 1;
  padding: 26px 20px;
  border-radius: 6px;
  align-items: center;
  transition: 0.1s ease-out;
  box-shadow: 0px 4px 14px rgba(70, 73, 88, 0);
  cursor: pointer;
  transform: translateZ(0);
  text-align: left;

  &:hover {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);
  }

  &:focus,
  &:active {
    box-shadow: 0px 4px 34px rgba(70, 73, 88, 0.1);
    outline: none;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    padding: 48px 40px;
  }

  // .button.small
  &.small {
    padding: 16px 20px;
    border-radius: 8px;

    @media (min-width: $npa-desktop-small-min-width) {
      padding: 20px;
      border-radius: 8px;
    }
  }

  // .button.medium
  &.medium {
    padding: 16px 20px;
    border-radius: 8px;

    @media (min-width: $npa-desktop-small-min-width) {
      padding: 20px;
      border-radius: 12px;
    }
  }

  // .button.large
  &.large {
    border-radius: 16px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 20px;
    }
  }

  // .button.active
  &.active {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);

    &:focus,
    &:active {
      box-shadow: 0px 4px 34px rgba(70, 73, 88, 0.1);
    }

    // .button.active .icon_selected
    .icon_selected {
      opacity: 1;
    }
  }
}

.text {
  font-family: $primary-font, $primary-font-fallback;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #464958;
  flex: 1;
  border-right: 1px solid $gray-900c;
  padding-right: 12px;

  // .text:last-child
  &:last-child {
    border-right: none;
    padding-right: 0;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 24px;
    line-height: 36px;
    padding-right: 24px;
  }

  // .text .small
  &.small {
    border-right: none;
    font-size: 14px;
    line-height: 20px;

    @media (min-width: $npa-desktop-small-min-width) {
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.info {
  margin-left: 12px;
  color: $cool-black-500;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-left: 24px;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  // border: 2px solid #f58025;
  margin-right: 12px;
  flex-shrink: 0;
  position: relative;
  fill: currentColor;
  color: $primary-color;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-right: 24px;
  }
}

.icon_unselected {
  position: absolute;
  top: 0;
  left: 0;
}

.icon_selected {
  opacity: 0;
  transition: 0.2s ease-in-out;
}
