@import "styles/variables.scss";

.item {
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 14px rgba(70, 73, 88, 0);
  transition: 0.1s ease-out;
  transform: translateZ(0);
  margin-bottom: 20px;

  // .item:last-child
  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);
  }

  // .item.active
  &.active {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);
  }

  &.small {
    border-radius: 8px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 8px;
    }
  }

  &.medium {
    border-radius: 8px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 12px;
    }
  }

  &.large {
    border-radius: 16px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 20px;
    }
  }
}

.item_body {
  overflow: hidden;
}

.item_button {
  padding: 24px 20px;
  display: flex;
  align-items: center;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    padding: 48px 40px;
  }
}

.item_radio_iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #f58025;
  margin-right: 12px;
  flex-shrink: 0;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-right: 24px;
  }
}

.item_radio_icon {
  display: flex;
  width: 16px;
  height: 16px;
  background-color: #f58025;
  border-radius: 50%;
  transform: scale(0) translateZ(0);
  transition: 0.15s transform ease-in;

  .active & {
    transform: scale(1) translateZ(0);
  }
}

.icon_checkbox_Container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  // border: 2px solid #f58025;
  margin-right: 12px;
  flex-shrink: 0;
  position: relative;
  fill: currentColor;
  color: $primary-color;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-right: 24px;
  }
}

.icon_checkbox_unselected {
  position: absolute;
  top: 0;
  left: 0;
}

.icon_checkbox_selected {
  opacity: 0;
  transition: 0.2s ease-in-out;
}

.active {
  .icon_checkbox_selected {
    opacity: 1;
  }
}

.item_form {
  padding: 0 20px 20px 20px;
  @media (min-width: $npa-desktop-small-min-width) {
    padding: 0 100px 20px 88px;
  }
}

.info {
  margin-left: auto;
  color: $cool-black-500;
}
