@import "styles/variables.scss";

.feature_list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.feature {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: $cool-black-500;

  // .feature:last-child
  &:last-child {
    margin-bottom: 0;
  }

  // .feature_icon
  &_icon {
    margin-right: 8px;
    flex-shrink: 0;
  }

  // .feature_text
  &_text {
    transition: 0.2s color ease-in-out;
    font-family: $secondary-font, $primary-font-fallback;
  }

  // .feature_complete
  &_complete {
    color: $warm-orange;
  }

  // .feature_highlight .feature_icon
  &_highlight {
    color: $cool-black-500;
  }

  &_basic_font {
    font-size: 14px;
    line-height: 20px;

    @media (min-width: $npa-desktop-small-min-width) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.feature_tertiary {
  color: $white-color;

  // .feature_tertiary .feature_complete
  .feature_complete {
    color: $white-color;
  }

  // .feature_tertiary .feature_highlight .feature_icon
  .feature_highlight {
    color: $white-color;
    // .feature_tertiary .feature_highlight .feature_icon
    .feature_icon {
      color: $white-color;
    }
  }
}

.iconAlignmentTop .feature_icon {
  align-self: flex-start;
}

.font13 {
  font-size: 13px;
  line-height: 20px;
}

.font14 {
  font-size: 14px;
  line-height: 20px;
}

@media (min-width: $npa-tablet-min-width) {
  .tabletFont13 {
    font-size: 13px;
    line-height: 20px;
  }

  .tabletFont14 {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (min-width: $npa-desktop-small-min-width) {
  .desktopFont13 {
    font-size: 13px;
    line-height: 20px;
  }

  .desktopFont14 {
    font-size: 14px;
    line-height: 20px;
  }
}

.bottomSpacing20 {
  margin-bottom: 20px;
}

.bottomSpacing12 {
  margin-bottom: 12px;
}

@media (min-width: $npa-tablet-min-width) {
  .tabletBottomSpacing20 {
    margin-bottom: 20px;
  }
}

@media (min-width: $npa-desktop-small-min-width) {
  .desktopBottomSpacing20 {
    margin-bottom: 20px;
  }
}
