@import "styles/variables.scss";

.container {
  padding: 0 16px;
  width: 632px;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: $npa-desktop-large-min-width) {
    width: 840px;
  }
}

.intro {
  margin-top: 40px;
  margin-bottom: 40px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  // .intro_title
  &_title {
    font-family: $primary-bold-font, $primary-font-fallback;
    font-style: normal;
    font-size: 32px;
    line-height: 36px;
    color: #464958;
    margin-bottom: 16px;

    @media (min-width: $npa-desktop-small-min-width) {
      font-size: 44px;
      line-height: 52px;
    }

    // .intro_title:last-child
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.body {
  margin-bottom: 40px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 60px;
  }
}

.feedback_wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: hidden;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.feedback_container {
  overflow: hidden;
}

.selection {
  // .selection_item
  &_item {
    display: flex;
    margin-bottom: 20px;

    // .selection_item:last-child
    &:last-child {
      margin-bottom: 0;
    }
  }
}
