@import "styles/variables.scss";

.container {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.checkbox {
  border: none;
  appearance: none;
  background: white;
  display: flex;
  flex: 1;
  padding: 12px 20px;
  border-radius: 6px;
  align-items: flex-start;
  transition: 0.1s ease-out;
  box-shadow: 0px 4px 14px rgba(70, 73, 88, 0);
  cursor: pointer;
  transform: translateZ(0);

  &:hover {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);
  }

  &:focus,
  &:active {
    box-shadow: 0px 4px 34px rgba(70, 73, 88, 0.1);
    outline: none;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    padding: 20px;
  }

  &.small {
    border-radius: 8px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 8px;
    }
  }

  &.medium {
    border-radius: 8px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 12px;
    }
  }

  &.large {
    border-radius: 16px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 20px;
    }
  }

  // .checkbox.checked
  &.checked {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);

    &:focus,
    &:active {
      box-shadow: 0px 4px 34px rgba(70, 73, 88, 0.1);
    }
  }
}

.shadowless {
  box-shadow: none;
  background-color: $senary-monochrome;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
  }

  &.checked {
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}

.input {
  display: none;
}

.text {
  font-family: $primary-font, $primary-font-fallback;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $cool-black;

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 18px;
    line-height: 28px;

    &.smallText {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  flex-shrink: 0;
  position: relative;
  fill: currentColor;
  color: $primary-color;
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
}

.errorText {
  color: #cc0000;
  font-family: $primary-font, $primary-font-fallback;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 13px;
  }
}
