@import "styles/variables.scss";

.container {
  padding: 0 16px;
  width: 632px;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: $npa-desktop-large-min-width) {
    width: 840px;
  }
}

.multiple_properties_checkbox {
  width: 100%;
}

.address_link_list {
  margin-top: 0;
  margin-bottom: 21px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 40px;
  }
}

.address_link_item {
  appearance: none;
  border: none;
  border-radius: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  color: $genesis-orange;
  transition: 0.2s color ease-in-out;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
  margin-right: 20px;
  padding: 0;

  // .address_link_item:hover
  &:hover {
    color: $genesis-orange-700;
  }

  // .address_link_item:active
  // .address_link_item:focus
  &:active,
  &:focus {
    outline: none;
  }

  // .address_link_item:last-child
  &:last-child {
    margin-right: 0;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 16px;
    line-height: 24px;
    margin-right: 40px;
  }
}

.address_link_item_icon {
  margin-left: 4px;
}

.address_details_container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.feedback_wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: hidden;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.feedback_container {
  overflow: hidden;
}
