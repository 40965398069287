@import "styles/variables.scss";

.wrapper {
  width: 100%;
}

.page_content_section {
  display: flex;
  flex-direction: column;

  @media (min-width: $npa-desktop-small-min-width) {
    flex-direction: row;
  }
}

.container {
  padding: 0 16px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $npa-desktop-small-min-width) {
    padding: 0 48px;
    margin-left: 0;
  }
}

.body {
  margin-left: -16px;
  margin-top: -48px;
  margin-right: -16px;
  margin-bottom: 0;
  padding-top: 48px;
  padding-bottom: 18px;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  @media (min-width: $npa-desktop-small-min-width) {
    overflow: unset;
    margin-left: 0;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 60px;
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.feedback_wrapper {
  margin-top: 0;
  margin-bottom: 40px;
  overflow: hidden;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 0;
    margin-bottom: 60px;
  }
}

.feedback_container {
  overflow: hidden;
}

.row {
  display: flex;
  flex-direction: column;

  @media (min-width: $npa-desktop-small-min-width) {
    flex-direction: row;
  }
}

.column {
  flex: 1;
  @media (min-width: $npa-desktop-small-min-width) {
    margin-left: 24px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.plan_row {
  display: flex;
  flex-wrap: nowrap;
  padding-left: 16px;
  padding-right: 16px;
  overflow-y: hidden;
  overflow-x: auto;

  @media (min-width: $npa-desktop-small-min-width) {
    overflow: unset;
    padding-left: 48px;
    padding-right: 48px;
  }
}

.plan_column {
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-left: 0;
  }
}

.plan_card {
  margin-bottom: 34px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 40px;
  }
}

.plan_rates {
  margin-bottom: 12px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 16px;
  }
}

.plan_usage_info_button {
  font-size: 14px;
  line-height: 20px;

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 16px;
    line-height: 24px;
  }
}

.title_field_container {
  @media (min-width: $npa-desktop-small-min-width) {
    max-width: 184px;
  }
}

.text_container {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: $primary-font, $primary-font-fallback;
  font-size: 15px;
  line-height: 24px;

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 18px;
    line-height: 28px;
  }
}

.text_link {
  color: inherit;
  text-decoration: none;
  white-space: nowrap;

  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

.radio_label {
  margin-top: 0;
  margin-bottom: 20px;
  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 0;
  }
}

.radio_column {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 0;
  }
}

.nowrap {
  white-space: nowrap;
}

.feature_item_spacing {
  padding-bottom: 32px;
}
