@import "styles/variables.scss";

.footer {
  margin-top: 20px;
}

.disclaimer {
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: $cool-black-300;
  margin-top: 20px;
  margin-bottom: 20px;

  // .disclaimer p:first-child
  & p:first-child {
    margin-top: 0;
  }

  // .disclaimer p:last-child
  & p:last-child {
    margin-bottom: 0;
  }
}

.cta {
  border: none;
  appearance: none;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: $genesis-orange;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;

  &:focus,
  &:active {
    outline: none;
  }

  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: $genesis-orange-700;
  }
}

.cta_text {
  padding-bottom: 2px;
  border-bottom: 1px solid $genesis-orange;

  .cta:hover &,
  .cta:focus &,
  .cta:active & {
    border-bottom-color: transparent;
  }
}

.cta_icon {
  margin-left: 8px;
}
