@import "styles/variables.scss";

.container {
  padding: 0 16px;
  width: 632px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $npa-desktop-small-min-width) {
    width: 696px;
    padding: 0 48px;
    margin-left: 0;
  }
}

.feedback_wrapper {
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: hidden;
}

.feedback_container {
  overflow: hidden;
}

.body {
  margin-bottom: 40px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 60px;
  }
}

.small_print {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 12px;
  line-height: 20px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  // .small_print_link
  &_link,
  a {
    color: $genesis-orange;
    text-decoration: none;
    border-bottom: 1px solid $genesis-orange;
    padding-bottom: 2px;
    transition: 0.2s color ease-in-out;

    // .suggestions_footer_link:hover
    &:hover {
      color: $genesis-orange-700;
      border-bottom-color: transparent;
    }
  }
}

.selection {
  // .selection_item
  &_item {
    display: flex;
    margin-bottom: 20px;

    // .selection_item:last-child
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.feedback_wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: hidden;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.feedback_container {
  overflow: hidden;
}
