@import "styles/variables.scss";

.container {
  padding: 0 16px;
  width: 632px;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: $npa-desktop-large-min-width) {
    width: 840px;
  }
}

.feedback_wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: hidden;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.feedback_container {
  overflow: hidden;
}

.body {
  margin-bottom: 40px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 60px;
  }
}

.selection {
  // .selection_item
  &_item {
    display: flex;
    margin-bottom: 20px;

    // .selection_item:last-child
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.one_word {
  white-space: nowrap;
}

.intro_text {
  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 23px;
  }
}
