@import "styles/variables.scss";

.item {
  display: flex;
  direction: ltr;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  border-left: 1px solid #e9e9eb;
  max-width: 383px;
  // .item:first-child
  &:first-child {
    border-left-color: transparent;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    padding: 24px;
  }
}

.item_fluid {
  max-width: none;
}

.title {
  font-family: $primary-font, $primary-font-fallback;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $primary-monochrome;
  margin: 0;
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (min-width: $npa-desktop-small-min-width) {
    font-family: $secondary-font, $primary-font-fallback;
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 8px;
  }
}

.link {
  font-family: $primary-font, $primary-font-fallback;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: $secondar-color-2;
  text-decoration: none;

  // .link:visited
  // .link:hover
  // .link:active
  // .link:focus
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: $secondar-color-2;
    text-decoration: none;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 12px;
  }
}
