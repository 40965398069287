@import "styles/variables.scss";

.container {
  padding: 100px 16px;
  @media (min-width: $npa-desktop-small-min-width) {
    padding: 120px 24px;
  }
}

.main {
  @media (min-width: $npa-desktop-small-min-width) {
    padding-left: 156px;
    position: relative;
  }

  @media (min-width: $npa-desktop-extra-large-min-width) {
    padding-left: 208px;
  }
}

.logo {
  width: 84px;
  height: 84px;
  margin: 0 auto;
  margin-bottom: 62px;
  display: flex;

  @media (min-width: $npa-desktop-small-min-width) {
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0;
    margin: 0;
    width: 120px;
    height: auto;
  }
}
