@import "styles/variables.scss";

.control {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  &:hover .input {
    border-bottom-color: $primary-color;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 60px;
  }
}

.controlFormOrange {
  @extend .control;
  &:hover .input {
    border-bottom-color: $white-color;
  }

  // input field styles to override browser autofill styles
  .input:-webkit-autofill,
  .input:-webkit-autofill:hover,
  .input:-webkit-autofill:focus,
  .input:-webkit-autofill:active {
    border: none !important;
    border-bottom: 1px solid $white-color !important;
    caret-color: $white-color !important;
    -webkit-text-fill-color: $white-color !important;
    -webkit-box-shadow: 0 0 0px 1000px $primary-color inset, 0 0 0px transparent !important;
    box-shadow: 0 0 0px 1000px $primary-color inset, 0 0 0px transparent !important;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.size28 {
  margin-bottom: 28px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 40px;
  }
}

.label {
  font-family: $primary-font, $primary-font-fallback;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: $cool-black-300;
  margin-bottom: 4px;
  visibility: hidden;
  opacity: 0;
  transition: 0.3s opacity ease-in-out;
  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 13px;
  }
}

.input,
.dropdown {
  font-family: $primary-font, $primary-font-fallback;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: $cool-black;
  appearance: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid $cool-black-300;
  transition: 0.3s border-color ease-in-out;
  padding: 0;
  padding-bottom: 12px;
  background-color: transparent;
  width: 100%;

  &:focus {
    outline: none;
    border-bottom-color: $primary-color;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 18px;
  }
}

.input::placeholder {
  color: $cool-black-300;
  opacity: 1;
}

.input_orange {
  @extend .input;
  border-bottom: 1px solid $genesis-orange-300;
  color: $white-color;

  &:focus {
    outline: none;
    border-bottom-color: $white-color;
  }

  &::placeholder {
    color: $white-color;
  }
}

.white_input_label {
  color: $white-color;
}

.dropdown_container {
  position: relative;
  display: flex;
}

.dropdown {
  color: $cool-black-300;
  width: 100%;
  cursor: pointer;

  // .filled .dropdown
  .filled &,
  option {
    color: $cool-black;
  }
}

.dropdown_placeholder {
  display: none;
}

.dropdown_icon {
  position: absolute;
  top: 4px;
  right: 0;
  pointer-events: none;
}

.errorText {
  color: #cc0000;
  font-family: $primary-font, $primary-font-fallback;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 13px;
  }
}

.radio {
  border: none;
  appearance: none;
  background: white;
  display: flex;
  flex: 1;
  padding: 16px 20px;
  border-radius: 6px;
  align-items: center;
  transition: 0.1s ease-out;
  box-shadow: 0px 4px 14px rgba(70, 73, 88, 0);
  cursor: pointer;
  transform: translateZ(0);

  &:hover {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);
  }

  &:focus,
  &:active {
    box-shadow: 0px 4px 34px rgba(70, 73, 88, 0.1);
    outline: none;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    padding: 20px;
  }

  &.small {
    border-radius: 8px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 8px;
    }
  }

  &.medium {
    border-radius: 8px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 12px;
    }
  }

  &.large {
    border-radius: 16px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 20px;
    }
  }

  // .radio.checked
  &.checked {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);

    &:focus,
    &:active {
      box-shadow: 0px 4px 34px rgba(70, 73, 88, 0.1);
    }
  }

  // .radio_input
  &_input {
    display: none;
  }

  // .radio_text
  &_text {
    font-family: $primary-font, $primary-font-fallback;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: $cool-black;

    @media (min-width: $npa-desktop-small-min-width) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  // .radio_icon_container
  &_icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid #f58025;
    margin-right: 12px;
    flex-shrink: 0;
  }

  // .radio_icon
  &_icon {
    display: flex;
    width: 16px;
    height: 16px;
    background-color: #f58025;
    border-radius: 50%;
    transform: scale(0) translateZ(0);
    transition: 0.15s transform ease-in;

    // .checked .radio_icon
    .checked & {
      transform: scale(1) translateZ(0);
    }
  }
}

.checkbox {
  border: none;
  appearance: none;
  background: white;
  display: flex;
  flex: 1;
  padding: 16px 20px;
  border-radius: 6px;
  align-items: center;
  transition: 0.1s ease-out;
  box-shadow: 0px 4px 14px rgba(70, 73, 88, 0);
  cursor: pointer;
  transform: translateZ(0);

  &:hover {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);
  }

  &:focus,
  &:active {
    box-shadow: 0px 4px 34px rgba(70, 73, 88, 0.1);
    outline: none;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    padding: 20px;
  }

  &.small {
    border-radius: 8px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 8px;
    }
  }

  &.medium {
    border-radius: 8px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 12px;
    }
  }

  &.large {
    border-radius: 16px;

    @media (min-width: $npa-desktop-small-min-width) {
      border-radius: 20px;
    }
  }

  // .checkbox.checked
  &.checked {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);

    &:focus,
    &:active {
      box-shadow: 0px 4px 34px rgba(70, 73, 88, 0.1);
    }
  }

  // .checkbox_input
  &_input {
    display: none;
  }

  // .checkbox_text
  &_text {
    font-family: $primary-font, $primary-font-fallback;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: $cool-black;

    @media (min-width: $npa-desktop-small-min-width) {
      font-size: 18px;
      line-height: 28px;
    }
  }

  // .checkbox_icon_container
  &_icon_container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    flex-shrink: 0;
    position: relative;
    fill: currentColor;
    color: $primary-color;
  }

  // .checkbox_icon
  &_icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.filled {
  // .filled .label
  & .label {
    opacity: 1;
    visibility: visible;
  }
}

.error {
  // .error .input
  & .input {
    border-bottom-color: #cc0000;
  }
}

.halfWidth {
  width: 100%;
  @media (min-width: $npa-tablet-min-width) {
    width: 50%;
  }
}
