@import "styles/variables.scss";

.intro {
  margin-top: 40px;
  margin-bottom: 40px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

// .title
.title {
  font-family: $primary-bold-font, $primary-font-fallback;
  font-style: normal;
  font-size: 32px;
  line-height: 36px;
  color: $neutral-dark;
  margin-bottom: 20px;

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 44px;
    line-height: 52px;
  }

  // .title:last-child
  &:last-child {
    margin-bottom: 0;
  }
}

.section_title {
  font-family: $primary-bold-font, $primary-font-fallback;
  font-style: normal;
  font-size: 27px;
  line-height: 32px;
  color: $cool-black-900;
  margin-bottom: 20px;
  margin-top: 40px;

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 32px;
    line-height: 36px;
    margin-top: 60px;
  }

  // .title:last-child
  &:last-child {
    margin-bottom: 0;
  }
}

.text {
  font-family: $primary-font, $primary-font-fallback;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: $primary-monochrome;
  margin-top: 0;

  // .text:last-child
  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 24px;
    line-height: 36px;
  }

  // .text_link
  &_link {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    padding-bottom: 0;
    border-bottom: 2px solid $primary-monochrome;

    // .text_link:hover
    // .text_link:active
    // .text_link:focus
    &:hover,
    &:active,
    &:focus {
      color: $primary-monochrome;
      border-bottom-color: transparent;
    }
  }
}

.link_list {
  list-style-type: none;
  font-size: 14px;
  line-height: 20px;
  padding-left: 0;
  max-width: 600px;
  margin-top: 4px;

  @media (min-width: $npa-tablet-min-width) {
    margin-top: 24px;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 16px;
    line-height: 24px;
  }
}

.link_list_item {
  display: flex;
  align-items: center;
  padding: 16px 0;
  box-shadow: inset 0px -1px 0px #e9e9eb;
}

.link_term {
  display: inline-block;
  min-width: 120px;

  @media (min-width: $npa-desktop-small-min-width) {
    min-width: 180px;
  }
}

.link {
  color: $genesis-orange;

  &:focus,
  &:active {
    outline: none;
  }

  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: $genesis-orange-700;
  }
}
