@import "styles/variables.scss";

.container {
  padding: 0 16px;
  width: 632px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $npa-desktop-small-min-width) {
    width: 800px;
    padding: 0 48px;
    margin-left: 0;
  }
}

.intro {
  @media (min-width: $npa-desktop-small-min-width) {
    max-width: 600px;
  }
}

.item {
  margin-bottom: 20px;

  // .item:last-child
  &:last-child {
    margin-bottom: 0;
  }
}

.manual_payments {
  width: 100%;
}

.feedback_wrapper {
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: hidden;
}

.feedback_container {
  overflow: hidden;
}

.row {
  display: flex;
  flex-direction: column;

  @media (min-width: $npa-desktop-small-min-width) {
    flex-direction: row;
  }
}

.column {
  flex: 1;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-left: 24px;
  }

  &:first-child {
    margin-left: 0;
  }
}

.direct_debit_form {
  padding-bottom: 20px;

  @media (min-width: $npa-desktop-small-min-width) {
    padding-bottom: 40px;
  }
}

.credit_card_terms {
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 24px;
  margin-top: 20px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 40px;
    font-size: 18px;
    line-height: 28px;
  }
}

.terms_container {
  margin-top: 40px;
  @media (min-width: $npa-desktop-small-min-width) {
    padding-bottom: 60px;
  }
}

.link {
  color: $genesis-orange;
  text-decoration: none;
  border-bottom: 1px solid $genesis-orange;
  padding-bottom: 2px;
  transition: 0.2s color ease-in-out;

  // .suggestions_footer_link:hover
  &:hover {
    color: $genesis-orange-700;
    border-bottom-color: transparent;
  }
}

.credit_card_form {
  padding-inline: 36px;
  @media (min-width: $npa-desktop-small-min-width) {
    padding-inline: 0;
  }
} 

.add_credit_card_button {
  margin-top: 2px;
  display: flex;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #F3F3F5;
  padding: 10px;
  justify-content: space-between;
}

.card_details {
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    margin-left: 10px;
  }
}

.card_number {
  font-weight: 500;
  color: #464958;
}

.expiry {
  color: #6B6D79;
  font-size: 14px;
}

.fill-current:hover {
  cursor: pointer;
}

.feedback_container div {
    align-items: flex-start;
}