@import "styles/variables.scss";

.button {
  border: none;
  appearance: none;
  background: white;
  display: flex;
  flex: 1;
  padding: 30px 16px;
  border-radius: 16px;
  align-items: center;
  transition: 0.2s ease-out;
  cursor: pointer;
  transform: translateZ(0);
  text-align: left;

  &:hover {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);
  }

  &:focus,
  &:active {
    box-shadow: 0px 4px 34px rgba(70, 73, 88, 0.35);
    outline: none;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    padding: 30px 20px;
    border-radius: 20px;
  }

  // .button.active
  &.active {
    box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);
    background-color: $primary-color;
    color: white;

    &:hover {
      box-shadow: 0px 4px 14px rgba(70, 73, 88, 0.15);
    }

    &:focus,
    &:active {
      box-shadow: 0px 4px 34px rgba(70, 73, 88, 0.35);
    }

    // .button.active .text
    .text {
      color: white;
    }
  }

  // .button.disableShadow
  &.disableShadow {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }

    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}

.text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: $cool-black;
  flex: 1;

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 24px;
    line-height: 36px;
    padding-right: 24px;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  flex-shrink: 0;
  position: relative;
  fill: currentColor;
  color: $primary-color;
  transition: 0.2s ease-in-out;

  & img {
    width: 100%;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    margin-right: 40px;
  }
}

.text_container {
  text-align: left;
}

.subtitle_text {
  color: $genesis-orange-500;
  font-size: 15px;
  line-height: 24px;

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 18px;
    line-height: 28px;
  }

  &.active {
    color: $white-color;
  }
}
