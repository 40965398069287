@import "styles/variables.scss";

.container {
  width: 632px;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: $npa-desktop-large-min-width) {
    width: 840px;
  }
}

.body {
  padding: 0 16px;
  margin-bottom: 40px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 60px;
  }
}

.item {
  margin-bottom: 20px;

  // .item:last-child
  &:last-child {
    margin-bottom: 0;
  }
}

.lpgButton {
  width: 100%;
}

.multiple_properties_checkbox {
  width: 100%;
}

.address_details_wrapper {
  height: 0;
  overflow: hidden;
}

.address_details_container {
  overflow: hidden;
  margin-bottom: 28px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 40px;
  }
}

.address_link_list {
  margin-bottom: 21px;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 40px;
  }
}

.address_link_item {
  appearance: none;
  border: none;
  border-radius: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  color: $primary-color;
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  margin-right: 20px;
  padding: 0;

  // .address_link_item:active
  // .address_link_item:focus
  &:active,
  &:focus {
    outline: none;
  }

  // .address_link_item:last-child
  &:last-child {
    margin-right: 0;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 16px;
    line-height: 24px;
    margin-right: 40px;
  }
}

.address_link_item_icon {
  margin-left: 4px;
}

.registry_error_wrapper {
  height: 0;
  overflow: hidden;
}

.registry_error {
  overflow: hidden;
  margin-bottom: 28px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 40px;
  }
}

.feedback_wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: hidden;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.feedback_container {
  overflow: hidden;
}

.inputContainer {
  display: flex;
  align-items: stretch;
  overflow: hidden;
  background-color: $primary-color;
  width: 100%;
  position: relative;
}

.inputContainer .inputFieldContainer {
  // margin: 0;
  flex: 1;
}

.inputContainer .input {
  padding-right: 56px;
}

.input[disabled] {
  background-color: $primary-color;
}

%inputIcon {
  color: $white-color;
  position: absolute;
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
}

.inputIconText {
  @extend %inputIcon;
  opacity: 1;
}

.inputIconLoading {
  @extend %inputIcon;
  animation: spin 0.5s infinite linear;
  opacity: 0;
}

.inputIconContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $white-color;
  position: absolute;
  top: 20px;
  right: 0;
  width: 48px;
  height: 20px;

  // .inputIconContainer.busy
  &.busy {
    // .inputIconContainer.busy .inputIconText
    .inputIconText {
      opacity: 0;
    }
    // .inputIconContainer.busy .inputIconSearch
    .inputIconSearch {
      opacity: 0;
    }
    // .inputIconContainer.busy .inputIconLoading
    .inputIconLoading {
      opacity: 1;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
