@import "styles/variables.scss";

.container {
  margin-bottom: 40px;
  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 60px;
  }
}

.label {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 20px;
  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 0;
    font-size: 18px;
    line-height: 28px;
  }
}

.row {
  display: flex;
  flex-direction: column;

  @media (min-width: $npa-tablet-min-width) {
    margin-left: -12px;
    margin-right: -12px;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.column {
  flex: 1;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $npa-tablet-min-width) {
    padding-left: 12px;
    padding-right: 12px;
    flex-basis: 50%;
    flex-grow: 0;
  }
}

.errorText {
  color: #cc0000;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 13px;
  }
}
