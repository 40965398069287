@import "styles/variables.scss";

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg {
  fill: currentColor;
  stroke: none;
  width: 100%;
  height: 100%;
}

.size72 {
  width: 72px;
  height: 72px;
}

.size60 {
  width: 60px;
  height: 60px;
}

.size50 {
  width: 80px;
  height: 50px;
}

.size38 {
  width: 38px;
  height: 38px;
}

.size36 {
  width: 36px;
  height: 36px;
}

.size32 {
  width: 32px;
  height: 32px;
}

.size24 {
  width: 24px;
  height: 24px;
}

.size20 {
  width: 20px;
  height: 20px;
}

.size18 {
  width: 18px;
  height: 18px;
}

.size12 {
  width: 12px;
  height: 12px;
}

@media (min-width: $npa-tablet-min-width) {
  .tabletSize38 {
    width: 38px;
    height: 38px;
  }

  .tabletSize36 {
    width: 36px;
    height: 36px;
  }

  .tabletSize32 {
    width: 32px;
    height: 32px;
  }

  .tabletSize24 {
    width: 24px;
    height: 24px;
  }

  .tabletSize20 {
    width: 20px;
    height: 20px;
  }

  .tabletSize18 {
    width: 18px;
    height: 18px;
  }

  .tabletSize12 {
    width: 12px;
    height: 12px;
  }
}

@media (min-width: $npa-desktop-small-min-width) {
  .desktopSize96 {
    width: 96px;
    height: 96px;
  }

  .desktopSize72 {
    width: 72px;
    height: 72px;
  }

  .desktopSize38 {
    width: 38px;
    height: 38px;
  }

  .desktopSize36 {
    width: 36px;
    height: 36px;
  }

  .desktopSize32 {
    width: 32px;
    height: 32px;
  }

  .desktopSize24 {
    width: 24px;
    height: 24px;
  }

  .desktopSize20 {
    width: 20px;
    height: 20px;
  }

  .desktopSize18 {
    width: 18px;
    height: 18px;
  }

  .desktopSize12 {
    width: 12px;
    height: 12px;
  }
}
