@import "styles/variables.scss";

.card {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  padding-top: 40px;
  padding-right: 16px;
  padding-bottom: 20px;
  padding-left: 16px;
  border-radius: 20px;
  min-width: 212px;
  box-shadow: 0px 0px 20px rgba(238, 80, 13, 0.06);
  transform: translateY(0);
  transition: 0.3s transform ease-in-out, 0.3s box-shadow ease-in-out;
  position: relative;
  overflow: hidden;

  background: $white-color;

  @media (min-width: $npa-desktop-small-min-width) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (any-hover: none) {
    box-shadow: none;
  }
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: 0.3s opacity ease-in-out;
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
  backface-visibility: visible;
  transform-origin: bottom left;
  animation: scaleanimation 2s infinite alternate forwards;
  background-color: #e8370d;
  background: radial-gradient(
    148.56% 105.98% at 0% 100%,
    #ffcd00 0%,
    #f58025 49.48%,
    #f26526 100%
  );
  @media (any-hover: none) {
    animation: none;
  }
}

.title {
  margin: 0;
  margin-bottom: 8px;
  font-size: 27px;
  line-height: 30px;
  font-family: $primary-bold-font, $primary-font-fallback;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 12px;
    font-size: 32px;
    line-height: 36px;
    white-space: nowrap;
  }
}

.whitespace_wrap {
  white-space: normal;
}

.subtitle {
  margin-top: 0;
  min-height: 40px;
  margin-bottom: 32px;
  font-size: 14px;
  line-height: 20px;
  color: $white-color;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 24px;
  }
}

.body {
  flex: 1;
  margin-bottom: 60px;
}

.selected {
  color: white;
  box-shadow: 0px 10px 30px rgba(238, 80, 13, 0.45),
    0px -10px 30px rgba(255, 230, 128, 0.2);
  @media (min-width: $npa-desktop-small-min-width) {
    transform: translateY(-10px);
    box-shadow: 0px 20px 50px rgba(238, 80, 13, 0.45),
      0px -10px 50px rgba(255, 230, 128, 0.2);
  }

  @media (any-hover: none) {
    box-shadow: none;
    transform: translateY(0);
  }
}

@keyframes scaleanimation {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.8);
  }
}
@keyframes gradientanimation {
  0% {
    transform: translate3d(-10px, 25px, 0) scale(1.1);
  }
  25% {
    transform: translate3d(10px, 25px, 0) scale(1.1);
  }
  50% {
    transform: translate3d(10px, -25px, 0) scale(1.1);
  }
  75% {
    transform: translate3d(-10px, -25px, 0) scale(1.1);
  }
  100% {
    transform: translate3d(-10px, 25px, 0) scale(1.1);
  }
}
