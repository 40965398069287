@import "styles/variables.scss";

.item {
  display: flex;
  flex-direction: column;
  transition: 0.1s ease-out;
  transform: translateZ(0);
  margin-bottom: 20px;

  // .item:last-child
  &:last-child {
    margin-bottom: 0;
  }

  // .item.active
  &.active {
    background-color: #f58025;
    border-radius: 16px;
    color: white;
  }
}

.item_body {
  overflow: hidden;
}

.item_button {
  padding: 24px 20px;
  display: flex;
  align-items: center;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    padding: 48px 40px;
  }
}

.item_text_container {
  text-align: left;
}

.item_text {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 4px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 2px;
    font-size: 24px;
    line-height: 36px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.item_subtitle_text {
  font-size: 14px;
  line-height: 20px;

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 16px;
    line-height: 24px;
  }
}

.item_form {
  padding: 0 20px 20px 20px;
  @media (min-width: $npa-desktop-small-min-width) {
    padding: 0 100px 20px 132px;
  }
}
