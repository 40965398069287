@import "styles/variables.scss";

.container {
  padding: 0 16px;
  width: 632px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $npa-desktop-small-min-width) {
    width: 800px;
    padding: 0 48px;
    margin-left: 0;
  }
}

.intro {
  @media (min-width: $npa-desktop-small-min-width) {
    max-width: 600px;
  }
}

.item {
  margin-bottom: 20px;

  // .item:last-child
  &:last-child {
    margin-bottom: 0;
  }
}

.dateItem {
  margin-top: 60px;
  margin-bottom: 60px;
}

.fixed_day_radio {
  margin-bottom: 40px;
}

.manual_payments {
  width: 100%;
}

.feedback_wrapper {
  margin-top: 16px;
  margin-bottom: 16px;
  overflow: hidden;
}

.feedback_container {
  overflow: hidden;
}

.terms_container {
  margin-top: 40px;
  @media (min-width: $npa-desktop-small-min-width) {
    padding-bottom: 60px;
  }
}

.link {
  color: $genesis-orange;
  text-decoration: none;
  border-bottom: 1px solid $genesis-orange;
  padding-bottom: 2px;
  transition: 0.2s color ease-in-out;

  // .suggestions_footer_link:hover
  &:hover {
    color: $genesis-orange-700;
    border-bottom-color: transparent;
  }
}

.date_select_wrapper {
  width: 288px;
}
