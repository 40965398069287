@import "styles/variables.scss";

.container {
  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 80px;
    width: 366px;
    padding-left: 48px;
    padding-right: 48px;
    flex-shrink: 0;
  }

  @media (min-width: $npa-desktop-large-min-width) {
    margin-top: 120px;
    width: 392px;
  }
}

.header {
  display: none;
  margin-top: 0;
  margin-bottom: 20px;

  @media (min-width: $npa-desktop-small-min-width) {
    display: block;
  }
}

.header_button {
  border: none;
  border-radius: 0px 0px 24px 24px;
  text-align: left;
  background-color: $orange-100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding-top: 48px;
  width: calc(100% - 40px);
  cursor: pointer;
  min-height: 72px;
  margin-top: -18px;
  margin-left: 20px;
  margin-right: 20px;

  &:active,
  &:focus {
    outline: none;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    display: none;
  }

  // .header_button_icon_container
  &_icon_container {
    transform: rotate(0deg);
    transition: transform 0.3s ease;
    color: $warm-orange;
    border-radius: 60px;
    padding: 4px 8px;
    background-color: $orange-200;
    display: flex;
    justify-content: center;
    align-items: center;

    // .plans_visible .header_button_icon_container
    .plans_visible & {
      transform: rotate(180deg);
    }
  }

  // .header_button_text_container
  &_text_container {
    display: flex;
    flex-direction: column;
    padding-right: 16px;
  }

  // .header_button_text_line1
  &_text_line1 {
    font-family: $secondary-font, $primary-font-fallback;
    font-size: 14px;
    line-height: 20px;
  }

  // .header_button_text_line1
  &_text_line2 {
    color: $genesis-orange;
    font-family: $primary-font, $primary-font-fallback;
    font-size: 14px;
    line-height: 20px;
  }
}

// .plans_visible .header_button_icon_container {
//   transform: rotate(0deg);
// }

.desktopBody {
  display: none;

  @media (min-width: $npa-desktop-small-min-width) {
    display: block;
    width: 632px;
  }
}

.body {
  margin: 0 16px;
  max-width: 100%;

  .plans_visible & {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: 2px solid $genesis-orange;

    @media (min-width: $npa-desktop-small-min-width) {
      border-bottom: none;
      display: none;
    }
  }
}

.feature_list_container {
  margin-bottom: 20px;
}

.rates_container {
  margin-bottom: 16px;
}

.card_container {
  margin-bottom: 40px;
  width: 288px;
  max-width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.benefits {
  // .benefits_container
  &_container {
    order: 1;
    padding: 40px 16px;
    padding-top: 0;
    width: 632px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;

    @media (min-width: $npa-desktop-small-min-width) {
      margin-top: 80px;
      width: 488px;
      padding-left: 48px;
      padding-right: 48px;
      flex-shrink: 1;
      order: 0;
    }

    @media (min-width: $npa-desktop-large-min-width) {
      margin-top: 120px;
    }
  }

  // .benefits_feature_list_container
  &_feature_list_container {
    margin-bottom: 60px;
    @media (min-width: $npa-desktop-small-min-width) {
      margin-bottom: 80px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  // .benefits_header
  &_header {
    font-family: $primary-bold-font, $primary-font-fallback;
    font-size: 27px;
    line-height: 32px;
    margin-top: 0;
    @media (min-width: $npa-desktop-small-min-width) {
      font-size: 32px;
      line-height: 36px;
    }
  }

  // .benefits_subheading
  &_subheading {
    margin-bottom: 8px;
    margin-top: 16px;
    font-size: 15px;
    line-height: 24px;

    @media (min-width: $npa-desktop-small-min-width) {
      font-size: 18px;
      line-height: 28px;
      margin-top: 20px;
    }
  }
}

.small_print {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: $tertiary-400;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 40px;

    &:last-child {
      margin-top: 8px;
    }
  }

  // .small_print_link
  &_link {
    color: $genesis-orange;
    text-decoration: none;
    border-bottom: 1px solid $genesis-orange;
    padding-bottom: 2px;
    transition: 0.2s color ease-in-out;

    // .small_print_link:hover
    &:hover {
      color: $genesis-orange-700;
      border-bottom-color: transparent;
    }
  }
}

.plan_card_highlight_items {
  background: rgba(70, 73, 88, 0.2);
  border-radius: 8px;
  margin-bottom: 32px;
  padding: 6px;
}

.plan_card_feature_item {
  padding-left: 6px;
}
