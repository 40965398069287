@import "styles/variables.scss";

.container {
  padding: 0 16px;
  width: 632px;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: $npa-desktop-large-min-width) {
    width: 840px;
  }
}

.item {
  margin-bottom: 20px;

  // .item:last-child
  &:last-child {
    margin-bottom: 0;
  }
}

.body {
  margin-bottom: 40px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-bottom: 60px;
  }
}

.feedback_wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: hidden;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.feedback_container {
  overflow: hidden;
}

.text_container {
  margin-top: 0;
  margin-bottom: 20px;
  font-family: $primary-font, $primary-font-fallback;
  font-size: 15px;
  line-height: 24px;

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 18px;
    line-height: 28px;
  }
}
