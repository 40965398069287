@import "styles/variables.scss";

.container {
  padding: 0 16px;
  width: 632px;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: $npa-desktop-small-min-width) {
    width: 1232px;
    max-width: calc(100vw - 120px);
  }
}

.header {
  &-wrapper {
    height: 600px;
    width: 100vw;
    margin-left: calc(50% - 50vw);

    @media screen and (min-width: $npa-desktop-small-min-width) {
      padding: 60px 60px 0;
      height: 700px;
    }
  }

  &-background {
    height: 100%;
    width: 100%;
    max-width: 1320px;
    margin: auto;
    padding: 20px 16px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    @media screen and (min-width: $npa-tablet-min-width) {
      padding: 80px 60px;
    }

    @media screen and (min-width: $npa-desktop-small-min-width) {
      padding: 100px 80px;
      border-radius: 20px;
    }
  }

  &-background-image {
    background-image: url("/images/genesis-join_success_res_mobile-and-tablet.jpg");

    @media screen and (-webkit-min-device-pixel-ratio: 2) {
      background-image: url("/images/genesis-join_success_res_mobile-and-tablet@2x.jpg");
    }

    @media screen and (min-width: $npa-desktop-small-min-width) {
      background-image: url("/images/genesis-join_success_res_desktop.jpg");
    }

    @media screen and (min-width: $npa-desktop-small-min-width) and (-webkit-min-device-pixel-ratio: 2) {
      background-image: url("/images/genesis-join_success_res_desktop@2x.jpg");
    }
  }

  &-background-image-ev {
    background-image: url("/images/Mobile-Tablet_EV-1916x1200px.jpg");

    @media screen and (-webkit-min-device-pixel-ratio: 2) {
      background-image: url("/images/Mobile-Tablet_EV-1916x1200px@2x.jpg");
    }

    @media screen and (min-width: $npa-desktop-small-min-width) {
      background-image: url("/images/Desktop_EV-2640x1400px.jpg");
    }

    @media screen and (min-width: $npa-desktop-small-min-width) and (-webkit-min-device-pixel-ratio: 2) {
      background-image: url("/images/Desktop_EV-2640x1400px@2x.jpg");
    }
  }

  &-logo {
    height: 80px;
    width: auto;

    @media screen and (min-width: $npa-desktop-small-min-width) {
      height: 120px;
    }
  }

  &-text {
    font-family: $primary-bold-font, $primary-font-fallback;
    font-size: 50px;
    line-height: 60px;
    color: $white-color;
    position: absolute;
    bottom: 80px;
    left: 16px;
    right: 16px;
    margin-bottom: 0;
    margin-top: 40px;

    @media screen and (min-width: $npa-tablet-min-width) {
      left: 60px;
      right: 60px;
    }

    @media screen and (min-width: $npa-desktop-small-min-width) {
      font-size: 80px;
      line-height: 96px;
      position: initial;
      max-width: 700px;
    }
  }
}

.content {
  &-wrapper {
    @media screen and (min-width: $npa-desktop-small-min-width) {
      display: flex;
      flex-direction: row-reverse;
      gap: 60px;
    }
  }

  &-sidebar {
    @media screen and (min-width: $npa-desktop-small-min-width) {
      flex: 1;
    }

    &-plan-card {
      width: 288px;
      min-height: 400px;
      margin-top: -40px;
      margin-bottom: 40px;
      padding: 40px 30px 20px !important;

      @media screen and (min-width: $npa-desktop-small-min-width) {
        margin: -120px auto 40px;
      }

      h3 {
        white-space: initial;
      }

      &-highlight-items {
        background: rgba(70, 73, 88, 0.2);
        border-radius: 8px;
        margin-bottom: 32px;
        padding: 6px;
      }

      &-feature-item {
        padding-left: 6px;
      }
    }

    &-discount-note {
      margin-bottom: 20px;
      font-size: 13px;
      line-height: 20px;
      color: $tertiary-400;

      @media screen and (min-width: $npa-desktop-small-min-width) {
        width: 352px;
        margin: 20px auto;
        font-size: 14px;
      }
    }

    &-rates {
      @media screen and (min-width: $npa-desktop-small-min-width) {
        width: 320px;
        margin: auto;
      }

      &-footer {
        margin-top: 20px;
      }

      &-disclaimer {
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        color: $cool-black-300;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      &-footer_cta {
        border: none;
        appearance: none;
        border-radius: 0;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: $genesis-orange;
        font-size: 16px;
        line-height: 24px;
        text-decoration: none;

        &:focus,
        &:active {
          outline: none;
        }

        &:visited,
        &:hover,
        &:active,
        &:focus {
          color: $genesis-orange-700;
        }
      }

      &-footer_cta_text {
        padding-bottom: 2px;
        border-bottom: 1px solid $genesis-orange;

        // .rates_footer_cta .rates_footer_cta_text
        .rates_footer_cta:hover &,
        .rates_footer_cta:focus &,
        .rates_footer_cta:active & {
          border-bottom-color: transparent;
        }
      }

      &-footer_cta_icon {
        margin-left: 8px;
      }
    }
  }

  &-intro {
    &-wrapper {
      margin-top: 60px;
      margin-bottom: 0;
    }

    @media screen and (min-width: $npa-desktop-small-min-width) {
      flex: 2;
    }
  }
}

.separator {
  height: 4px;
  border: none;
  border-radius: 2px;
  background-color: $genesis-orange;
  margin-top: 80px;
  margin-bottom: 40px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}

.small_print {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: $tertiary-400;
  padding: 0 16px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 20px;

    &:last-child {
      margin-top: 8px;
    }
  }

  // .small_print_link
  &_link {
    color: $genesis-orange;
    text-decoration: none;
    border-bottom: 1px solid $genesis-orange;
    padding-bottom: 2px;
    transition: 0.2s color ease-in-out;

    // .small_print_link:hover
    &:hover {
      color: $genesis-orange-700;
      border-bottom-color: transparent;
    }
  }
}
