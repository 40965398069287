@import "styles/variables.scss";

.badge {
  border: none;
  appearance: none;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  position: absolute;
  top: 0px;
  right: 0px;
  background: radial-gradient(
    148.56% 105.98% at 0% 100%,
    #f26526 0%,
    #f58025 49.48%,
    #ffcd00 100%
  );

  &.white {
    background: $white-color;
  }
}

.text {
  font-family: $secondary-font, $primary-font-fallback;
  font-style: normal;
  color: $white-color;
  text-align: right;

  &.white {
    color: $cool-black;
  }
}
