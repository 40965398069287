@import "styles/variables.scss";

%feedback {
  padding: 16px;
  border: 2px solid transparent;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;

  @media (min-width: $npa-desktop-small-min-width) {
    padding: 20px;
  }
}

.feedback {
  @extend %feedback;

  // .feedback_icon_container
  &_icon_container {
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    flex-shrink: 0;

    @media (min-width: $npa-desktop-small-min-width) {
      width: 24px;
      height: 24px;
    }
  }

  // .feedback_title
  &_title {
    font-family: $primary-bold-font, $primary-font-fallback;
    font-size: 14px;
    line-height: 20px;
    color: #464958;
    margin-top: 0;
    margin-bottom: 4px;

    @media (min-width: $npa-desktop-small-min-width) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  // .feedback_text
  &_text {
    font-family: $primary-font, $primary-font-fallback;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #464958;
    margin: 0;

    @media (min-width: $npa-desktop-small-min-width) {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.text_link {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  padding-bottom: 0;
  border-bottom: 2px solid $primary-monochrome;

  // .text_link:hover
  // .text_link:active
  // .text_link:focus
  &:hover,
  &:active,
  &:focus {
    color: $primary-monochrome;
    border-bottom-color: transparent;
  }
}

.feedback_info {
  @extend %feedback;
  border-color: #00a9ff;
  background-color: #e0f5ff;
}

.feedback_success {
  @extend %feedback;
  border-color: #7ac92f;
  background-color: #eff9e6;
}

.feedback_warning {
  @extend %feedback;
  border-color: #F26526;
  background-color: #FCE0D4;
}

.feedback_error {
  @extend %feedback;
  border-color: #cc0000;
  background-color: #f9e0e0;
}
