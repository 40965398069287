@import "styles/variables.scss";

%button {
  appearance: none;
  border: none;
  background: transparent;
  border-radius: 30px;
  border: 2px solid transparent;
  font-family: $primary-font, $primary-font-fallback;
  font-style: normal;
  font-weight: normal;
  font-size: 16px; // 18px;
  line-height: 16px; // 20px;
  text-align: center;
  box-shadow: 0px 12px 20px rgba(70, 73, 88, 0);
  transition: 0.3s ease-out;
  transform: translateY(0) translateZ(0);
  cursor: pointer;
  text-decoration: none;

  &:visited {
    text-decoration: none;
  }

  &:hover,
  &:focus {
    box-shadow: 0px 12px 20px rgba(70, 73, 88, 0.3);
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
  }

  &[disabled],
  &.disabled {
    cursor: default;
    &:hover,
    &:focus {
      box-shadow: none;
      transform: translateY(0);
    }
  }

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 18px;
    line-height: 20px;
  }
}

%link {
  appearance: none;
  border: none;
  text-align: left;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  align-items: center;
}

%iconButton {
  appearance: none;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s ease-out;

  &:hover,
  &:focus {
    box-shadow: 0px 12px 20px rgba(70, 73, 88, 0.3);
    transform: translateY(-2px);
  }

  &:visited {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  &[disabled],
  &.disabled {
    cursor: default;
    &:hover,
    &:focus {
      box-shadow: none;
      transform: translateY(0);
    }
  }
}

.icon {
  &:last-child {
    margin-left: 4px;

    @media (min-width: $npa-desktop-small-min-width) {
      margin-left: 8px;
    }
  }
}

.primaryLink {
  @extend %link;
  color: $genesis-orange;

  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: $genesis-orange-700;
    outline: none;
  }
}

.primaryFill {
  @extend %button;
  background: $primary-color;
  color: $white-color;

  &:visited {
    color: $white-color;
  }

  &:active {
    background: genesis-orange-900;
    box-shadow: 0px 12px 20px rgba(245, 128, 37, 0.2);
  }

  &[disabled],
  &.disabled {
    background-color: #f4ba8c;
  }
}

.primaryFillWarmOrange {
  @extend .primaryFill;
  background: $warm-orange;
}

.primaryFillIcon {
  @extend .primaryFill;
  display: flex;
  justify-content: space-around;
}

.primaryRoundIcon {
  @extend %iconButton;
  background-color: $primary-color;
  color: $white-color;
}

.secondaryFill {
  @extend %button;
  background: $primary-monochrome;
  color: $white-color;

  &:visited {
    color: $white-color;
  }

  &:hover {
    background: $cool-black-700;
  }

  &:active {
    background: $cool-black-700;
    box-shadow: 0px 12px 20px rgba(70, 73, 88, 0.3);
  }

  &[disabled],
  &.disabled {
    background-color: #f4ba8c;
  }
}

.secondaryOutline {
  @extend %button;
  color: $primary-monochrome;
  background-color: transparent;
  border: 2px solid $cool-black-700;

  &:hover,
  &:focus {
    background-color: $cool-black-700;
    color: $white-color;
  }

  &:active {
    box-shadow: 0px 12px 20px rgba(70, 73, 88, 0.1);
  }
}

.secondaryRoundIcon {
  @extend %iconButton;
  background-color: transparent;
  color: $primary-monochrome;
  border: 1px solid $primary-monochrome;
  display: flex;
  align-items: center;
}

.tertiaryLink {
  @extend %link;
  color: $cool-black-500;

  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: $cool-black-700;
    outline: none;
  }
}

.tertiaryFill {
  @extend %button;
  background: $white-color;
  color: $cool-black-500;

  &:visited {
    color: $cool-black-500;
  }

  &:hover {
    background: $white-color;
  }

  &:active {
    background: $white-color;
    box-shadow: 0px 12px 20px rgba(70, 73, 88, 0.3);
  }

  &[disabled],
  &.disabled {
    background-color: $white-color;
  }
}

.tertiaryFillWithBorder {
  @extend .tertiaryFill;
  border: 2px solid $cool-black-500;
}

.tertiaryRoundIcon {
  @extend %iconButton;
  background: $cool-black-500;
  color: $white-color;
}

.regular {
  min-width: 140px;
  padding: 14px 18px;

  @media (min-width: $npa-desktop-small-min-width) {
    min-width: 180px;
    padding: 18px 23px;
  }
}

.medium {
  min-width: 165px;
  padding: 18px 20px;
}

.small {
  min-width: 150px;
  padding: 14px 20px;
}

.extraSmall {
  min-width: 120px;
  padding: 10px 14px;
}

.round {
  width: 40px;
  height: 40px;
  padding: 6px;

  @media (min-width: $npa-desktop-small-min-width) {
    width: 52px;
    height: 52px;
    padding: 7px;
  }
}
