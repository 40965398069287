@import "styles/variables.scss";

.container {
  padding: 0 16px;
  width: 632px;
  max-width: 100%;
  margin: 0 auto;

  @media (min-width: $npa-desktop-small-min-width) {
    width: 1232px;
    max-width: calc(100vw - 120px);
  }
}

.header {
  &-wrapper {
    height: 600px;
    width: 100vw;
    margin-left: calc(50% - 50vw);

    @media screen and (min-width: $npa-desktop-small-min-width) {
      padding: 60px 60px 0;
      height: 700px;
    }
  }

  &-background {
    height: 100%;
    width: 100%;
    max-width: 1320px;
    margin: auto;
    padding: 20px 16px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-position: center;
    background-size: cover;
    position: relative;

    @media screen and (min-width: $npa-tablet-min-width) {
      padding: 80px 60px;
    }

    @media screen and (min-width: $npa-desktop-small-min-width) {
      padding: 100px 80px;
      border-radius: 20px;
    }

    &-success {
      background-image: url("/images/genesis-join_success_bus_mobile-and-tablet.jpg");
      @media screen and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url("/images/genesis-join_success_bus_mobile-and-tablet@2x.jpg");
      }

      @media screen and (min-width: $npa-desktop-small-min-width) {
        background-image: url("/images/genesis-join_success_bus_desktop.jpg");
      }

      @media screen and (min-width: $npa-desktop-small-min-width) and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url("/images/genesis-join_success_bus_desktop@2x.jpg");
      }
    }

    &-callback {
      background-image: url("/images/genesis-join_callback_bus_mobile-and-tablet.jpg");
      @media screen and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url("/images/genesis-join_callback_bus_mobile-and-tablet@2x.jpg");
      }

      @media screen and (min-width: $npa-desktop-small-min-width) {
        background-image: url("/images/genesis-join_callback_bus_desktop.jpg");
      }

      @media screen and (min-width: $npa-desktop-small-min-width) and (-webkit-min-device-pixel-ratio: 2) {
        background-image: url("/images/genesis-join_callback_bus_desktop@2x.jpg");
      }
    }
  }
  &-logo {
    height: 80px;
    width: auto;

    @media screen and (min-width: $npa-desktop-small-min-width) {
      height: 120px;
    }
  }

  &-text {
    font-family: $primary-bold-font, $primary-font-fallback;
    font-size: 50px;
    line-height: 60px;
    color: $white-color;
    position: absolute;
    bottom: 80px;
    left: 16px;
    right: 16px;
    margin-bottom: 0;
    margin-top: 40px;

    @media screen and (min-width: $npa-tablet-min-width) {
      left: 60px;
      right: 60px;
    }

    @media screen and (min-width: $npa-desktop-small-min-width) {
      font-size: 80px;
      line-height: 96px;
      position: initial;
      max-width: 700px;
    }
  }
}

.content {
  &-wrapper {
    @media screen and (min-width: $npa-desktop-small-min-width) {
      display: flex;
      flex-direction: row-reverse;
      gap: 60px;
    }
  }

  &-sidebar {
    @media screen and (min-width: $npa-desktop-small-min-width) {
      flex: 1;
    }

    &-plan-card {
      width: 288px;
      min-height: 400px;
      margin-top: -40px;
      margin-bottom: 40px;
      padding: 40px 30px 60px !important;

      @media screen and (min-width: $npa-desktop-small-min-width) {
        margin: -120px auto 40px;
      }

      h3 {
        margin-bottom: 40px;
        white-space: initial;
      }
    }

    &-rates {
      @media screen and (min-width: $npa-desktop-small-min-width) {
        width: 320px;
        margin: auto;
      }
    }
  }

  &-intro {
    &-wrapper {
      margin-top: 60px;
      margin-bottom: 0;
    }

    @media screen and (min-width: $npa-desktop-small-min-width) {
      flex: 2;
    }
  }
}

.separator {
  height: 4px;
  border: none;
  border-radius: 2px;
  background-color: $genesis-orange;
  margin-top: 80px;
  margin-bottom: 40px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}
