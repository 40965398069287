@import "styles/variables.scss";

.rates {
  background-color: $cool-black-50;
  border-radius: 6px;
  padding: 13px 16px;
  margin: 8px 0px;

  &:last-child {
    margin-bottom: 0;
  }

  &.placehoder {
    background-color: $warm-orange-50;
    border: 2px solid $warm-orange-100;
  }

  &.highlight-mobile {
    background-color: $genesis-orange-50;
    @media (min-width: $npa-desktop-small-min-width) {
      background-color: $cool-black-50;
    }
  }
}

// .rates_header
.header {
  display: flex;
  align-items: center;
  margin: 0;
  color: $genesis-orange;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 6px;

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 16px;
    line-height: 24px;
  }
}

// .rates_header_icon
.icon {
  margin-right: 8px;
  margin-left: -4px;

  @media (min-width: $npa-desktop-small-min-width) {
    margin-left: -6px;
  }
}

// .rates_header_text
.header_text {
  font-family: $secondary-font, $primary-font-fallback;
}

// .rates_footer
.footer {
  margin-top: 20px;
}

// .rates_disclaimer
.disclaimer {
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: $cool-black-300;
  margin-top: 20px;
  margin-bottom: 20px;

  // .rates_disclaimer p:first-child
  & p:first-child {
    margin-top: 0;
  }

  // .rates_disclaimer p:last-child
  & p:last-child {
    margin-bottom: 0;
  }
}

// .rates_footer_cta
.footer_cta {
  border: none;
  appearance: none;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: $genesis-orange;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;

  &:focus,
  &:active {
    outline: none;
  }

  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: $genesis-orange-700;
  }
}

.rate_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 20px;
  padding: 8px 0;

  &_header {
    margin-right: 16px;
    display: inline-flex;
    flex-wrap: wrap;
  }

  &_title:first-child {
    margin-right: 4px;
  }

  &_subtitle {
    font-size: 10px;
    line-height: 20px;
    color: $cool-black-400;
    font-family: $secondary-font, $primary-font-fallback;
  }

  &_text {
    text-align: end;
    white-space: nowrap;
  }

  @media (min-width: $npa-desktop-small-min-width) {
    font-size: 14px;
    line-height: 20px;

    &_header {
      margin-right: 20px;
    }
  }
}

.rate_item_small {
  font-size: 13px;
  line-height: 20px;
  color: $cool-black-300;
}

.seasonal_rate_title {
  padding-bottom: 0;
}
